import { CheckRadioField, FormField } from "oddsgate-ds";
import { ErrorMessage, Field } from "formik";

import CustomErrorMessage from "./CustomErrorMessage";
import { ICheckbox } from "../fieldProps";
import getGfFieldId from "@/lib/wordpress/forms/getGfFieldId";
import getGfHiddenClassName from "@/lib/wordpress/forms/getHiddenClassName";

function getMergedCheckboxes(choices: any, inputs: any) {
  let checkboxes: any[] = [];

  choices.forEach((checkbox: any, index: number) => {
    checkboxes.push({
      ...checkbox,
      ...inputs[index],
    });
  });

  return checkboxes;
}

export default function Checkbox({
  cssClass,
  choices,
  description,
  id,
  isRequired,
  inputs,
  label,
  visibility,
}: ICheckbox) {
  const fieldId = getGfFieldId(id);
  const isHiddenClass = getGfHiddenClassName(visibility);
  const checkboxes: Record<string, any> = getMergedCheckboxes(choices, inputs);

  return (
    <FormField id={fieldId} label={label} isCheckRadio={true}>
      {checkboxes.length > 0 &&
        checkboxes.map((checkbox: any, index: number) => {
          const { id, label, value } = checkbox;

          return (
            <CheckRadioField
              key={`${fieldId}-${index}`}
              id={`${fieldId}-${index}`}
              label={label}
            >
              <Field
                type={"checkbox"}
                id={`${fieldId}-${index}`}
                name={fieldId}
                value={value}
              />
            </CheckRadioField>
          );
        })}

      <ErrorMessage name={fieldId} component={CustomErrorMessage} />
    </FormField>
  );
}

"use client";

import { Separator } from "oddsgate-ds";
import getBlockStyles from "@/lib/wordpress/blocks/getBlockStyles";

export type IBlockSeparator = {
  attrs: {
    anchor?: string;
    type?: string,
    color?: string;
    height?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
};

export default function BlockSeparator({ attrs }: IBlockSeparator) {
  const { anchor, type = "solid", height, color, style, className } = attrs || {};

  return (
    <Separator
      // id={anchor || undefined}
      color={color}
      height={height}
      type={type}
      className={className}
    ></Separator>
  );
}

'use client';

import {
    Column,
    EmptyState,
    Loader,
    ProductCard,
    Row,
    Spacer,
    variables,
} from 'oddsgate-ds';
import { Suspense, useEffect, useRef, useState } from 'react';

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle';
import DisplayImage from '@/components/common/DisplayImage/DisplayImage';
import { IPost } from '@/lib/wordpress/_types/archiveGrid';
import Link from 'next/link';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import displayBlock from '@/lib/wordpress/blocks/displayBlock';
import { gsap } from 'gsap';
import { styled } from 'styled-components';
import { useGSAP } from '@gsap/react';
import { useGlobalContext } from '@/lib/globalContext';
import { useGetTranlatedRoute } from '@/lib/helpers/useGetRoute';

export type IBlockScrollingProducts = {
    attrs: {
        className?: string;
        grid?: {
            items: IPost[];
            total: number;
        };
    };
    innerBlocks: BlocksProps[];
};

const StyledGrid = styled.div`
    & > div {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        min-height: 100dvh;
    }
`;
const StyledGridWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
    margin-top: 4rem;
    gap: 4rem;

    @media only screen and (min-width: ${variables.responsiveMedia}) {
        margin-top: 0;
    }
`;

const StyledProductCard = styled(ProductCard)`
    display: inline-block;
    width: 18rem;
    /* transform: rotate(-25deg); */
    margin-right: 1rem;

    & > div {
        display: block;
        padding: 2rem;

        & img {
            margin: 12px auto;
        }
    }

    .firstRowWrapper & {
        /* transform: rotate(25deg); */
        & > div {
            border-radius: 48px 0;
        }
    }

    @media only screen and (min-width: 1600px) {
        width: 22rem;
    }
`;

export default function BlockScrollingProducts({
    attrs,
    innerBlocks,
}: IBlockScrollingProducts) {
    const state = useGlobalContext();
    const productUrl = useGetTranlatedRoute('product');

    const wrapper = useRef<HTMLDivElement>();

    const tl = useRef<any>();

    console.log('state', state);

    const { grid, className } = attrs || {};

    const [filteredPosts, setFilteredPosts] = useState<IPost[] | undefined>(
        grid?.items ?? []
    );

    useGSAP(
        () => {
            if (!filteredPosts?.length) return;
            // make the right edge "stick" to the scroll bar. force3D: true improves performance
            const productsSet = gsap.quickSetter('.products-divs', 'css');

            productsSet({
                transformOrigin: 'center center',
            });

            let proxy = { skew: 0 },
                clamp = gsap.utils.clamp(-60, 60); // don't let the skew go beyond 30 degrees.

            ScrollTrigger.create({
                scrub: false,
                onUpdate: (self) => {
                    let skew = clamp(self.getVelocity() / -300);
                    // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
                    if (Math.abs(skew) > Math.abs(proxy.skew)) {
                        proxy.skew = skew;
                        gsap.to(proxy, {
                            skew: 0,
                            duration: 0.8,
                            ease: 'none',
                            overwrite: true,
                            onUpdate: () =>
                                productsSet({ skewX: `${proxy.skew}deg` }),
                        });
                    }
                },
            });

            tl.current = gsap
                .timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: wrapper.current,
                        pin: true,
                        start: 'top top',
                        end: () => '+=1150',
                        scrub: 1,
                        invalidateOnRefresh: true,
                    },
                    defaults: { ease: 'none' },
                })
                .to(
                    '.firstRowWrapper .products-divs',
                    {
                        rotate: 25,
                    },
                    0
                )
                .to(
                    '.firstRowWrapper ',
                    {
                        xPercent: () => 120,
                    },
                    0
                )

                .to(
                    '.secondRowWrapper .products-divs',
                    {
                        rotate: -25,
                    },
                    0
                )
                .to(
                    '.secondRowWrapper ',
                    {
                        xPercent: () => -120,
                    },
                    0
                );
        },
        { scope: wrapper }
    );

    const firstRow = filteredPosts && filteredPosts.slice(0, 3);
    const firstRowInverted = [...(firstRow as any)].reverse();
    const secondRow = filteredPosts && [
        ...filteredPosts.slice(3, filteredPosts.length),
        ...firstRowInverted,
    ];

    return (
        <StyledGrid ref={wrapper as any} style={{}}>
            <div>
                <Spacer customHeight={'4rem'}></Spacer>
                <Row className="zIndex-5">
                    <Column md={5}>
                        {!!innerBlocks?.length &&
                            innerBlocks?.map((block: BlocksProps, i) => {
                                return displayBlock(block, i);
                            })}
                    </Column>
                    <Column md={7}>
                        {!filteredPosts?.length && (
                            <EmptyState
                                variant={'light'}
                                label={
                                    state?.dict?.common?.noResults
                                }></EmptyState>
                        )}

                        {(filteredPosts?.length ?? 0) > 0 && (
                            <StyledGridWrapper
                                className={'firstRowWrapper mb-10'}>
                                {firstRow &&
                                    firstRow.length !== 0 &&
                                    firstRow.map((post, i: number) => {
                                        return (
                                            <StyledProductCard
                                                key={`ProductCard-${i}`}
                                                imageElement={
                                                    <DisplayImage
                                                        url={
                                                            post?.image as string
                                                        }
                                                        alt={
                                                            post?.title
                                                                ? (post?.title as string)
                                                                : ''
                                                        }
                                                        width={250}
                                                        height={250}
                                                    />
                                                }
                                                title={post.title as string}
                                                className="products-divs"
                                                linkElement={
                                                    <Link
                                                        href={`${state.lang}/${productUrl}`}
                                                        aria-label={`Read more about ${post.title}`}
                                                        role="link"
                                                        className="linker"></Link>
                                                }
                                            />
                                        );
                                    })}
                            </StyledGridWrapper>
                        )}
                    </Column>
                </Row>
                <Row>
                    <Column md={12}>
                        <StyledGridWrapper className={'secondRowWrapper'}>
                            {secondRow &&
                                secondRow.length !== 0 &&
                                secondRow.map((post, i: number) => {
                                    return (
                                        <StyledProductCard
                                            key={`ProductCard-${i}`}
                                            imageElement={
                                                <DisplayImage
                                                    url={post?.image as string}
                                                    alt={
                                                        post?.title
                                                            ? (post?.title as string)
                                                            : ''
                                                    }
                                                    width={250}
                                                    height={250}
                                                />
                                            }
                                            title={post.title as string}
                                            className="products-divs"
                                            linkElement={
                                                <Link
                                                    href={`${state.lang}/${productUrl}`}
                                                    aria-label={`Read more about ${post.title}`}
                                                    role="link"
                                                    className="linker"></Link>
                                            }
                                        />
                                    );
                                })}
                        </StyledGridWrapper>
                    </Column>
                </Row>
                <Spacer customHeight={'4rem'}></Spacer>
            </div>
        </StyledGrid>
    );
}

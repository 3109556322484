"use client";

import { useEffect, useRef } from 'react';
import { useMediaMatch, variables, } from 'oddsgate-ds';

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle';
import DisplayImage from '@/components/common/DisplayImage/DisplayImage';
import { gsap } from 'gsap';
import { styled } from 'styled-components';
import { useGSAP } from '@gsap/react';

export type IBlockScrollingImgs = {
  attrs: {
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};

const StyledGrid = styled.div`
  position: relative;
  display: none;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;

  & > div{
    position: relative;
    will-change: transform;
    z-index: 1;

    &:first-child{
      grid-column: 4;
      grid-row: 1;
      z-index: -1;
    }

    &:nth-child(2){
      grid-column: 1;
      grid-row: 2;
    }

    &:nth-child(3){
      grid-column: 2;
      grid-row: 3;
      z-index: -1;
    }

    &:nth-child(4){
      grid-column: 1;
      grid-row: 5;
    }

    &:nth-child(5){
      grid-column: 4;
      grid-row: 5;
      z-index: -1;
    }

    & img{
      position: relative;
      width: 100%;
      will-change: transform, opacity;
    }
  }

  @media only screen and (min-width: ${variables.responsiveMedia}) {
    display: grid;
  }
`;

export default function BlockScrollingImgs({ attrs, innerBlocks }: IBlockScrollingImgs) {
  const wrapper = useRef<HTMLDivElement>();

  const isMobile = useMediaMatch();
  useGSAP(() => {
    if (wrapper.current && !isMobile) {
      const slides = Array.prototype.slice.call(wrapper?.current?.children);

      slides.map((item, i) => {
        const yPercentRandomVal = gsap.utils.random(-200, -50);

        gsap.to(item, {
          ease: 'none',
          yPercent: yPercentRandomVal,
          scrollTrigger: {
            trigger: item,
            start: "top bottom",
            // end: "top top",
            end: () => `+=${gsap.utils.random(350, 750)}px`,
            scrub: 1,
            invalidateOnRefresh: true,
          }
        });
      });

    }else{
      gsap.set([wrapper.current, ...Array.prototype.slice.call(wrapper?.current?.children)], { clearProps: true })
    }
  }, { dependencies: [isMobile], scope: wrapper })

  if (!innerBlocks?.length) return <></>;

  return (
    <StyledGrid ref={wrapper as any}>
      {!!innerBlocks?.length &&
        innerBlocks?.map((img, i) => {
          return (
            <div key={`img-${i}`}>
              <DisplayImage
                alt={img?.attrs?.alt ? img?.attrs?.alt : ""}
                url={img?.attrs?.url}
                width={img?.attrs?.width}
                height={img?.attrs?.height}
              />
            </div>
          )
        })}
    </StyledGrid>
  );
}

//@ts-nocheck
"use client";

import { Column, EmptyState, Loader, ProductsSlider, Row, Spacer, useMediaMatch, variables } from 'oddsgate-ds';
import { Suspense, useEffect, useRef, useState } from 'react';

import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle';
import DisplayImage from '@/components/common/DisplayImage/DisplayImage';
import { IPost } from '@/lib/wordpress/_types/archiveGrid';
import { useGlobalContext } from '@/lib/globalContext';

export type IBlockSliderProducts = {
  attrs: {
    className?: string
    grid?: {
      items: IPost[]
      total: number
    }
  };
  innerBlocks: BlocksProps[];
};



export default function BlockSliderProducts({ attrs, innerBlocks }: IBlockSliderProducts) {
  const { grid, className } = attrs || {};

  const state = useGlobalContext();

  const [filtersChange, setFiltersChange] = useState({
    page: 1,
  });

  const [loading, setLoading] = useState<boolean | null>(false);

  const [filteredPosts, setFilteredPosts] = useState(grid?.items);


  const newData =
    filteredPosts &&
      filteredPosts.length > 0 ?
      filteredPosts.map(block => {
        const result = {
          title: block.title,
          description: block?.excerpt,
          imageElement: (
            <DisplayImage
              url={block?.image as string}
              alt={block?.title ? (block?.title as string) : ""}
              width={250}
              height={250}
            />
          ),
        }

        return result
      }) : ([])

  if (!grid) return <></>;

  return (
    <div>
      {filteredPosts ? (
        <Row fluid={true}>
          {loading ? (
            <Column className="text-center pt-5 pb-5">
              <Loader
                label={state?.dict?.common?.loading}
                variant="light"
              ></Loader>
            </Column>
          ) : (
            <>
              <Suspense
                fallback={
                  <Loader
                    label={state?.dict?.common?.loading}
                    variant="light"
                  ></Loader>
                }
              >

                <ProductsSlider content={newData}></ProductsSlider>
              </Suspense>
            </>
          )}
        </Row>
      ) : (
        <Column className="pt-5 pb-5">
          <Spacer height={8}></Spacer>
          <EmptyState
            variant={"light"}
            label={state?.dict?.common?.noResults}
          ></EmptyState>
        </Column>
      )}
    </div>
  );
}

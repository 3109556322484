"use client";

import React, { useEffect, useState } from "react";

import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import { Modal } from "oddsgate-ds";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";
import { removeScroll } from "@/lib/helpers/scrollHelpers";
import { useGlobalContext } from "@/lib/globalContext";

export type IBlockModal = {
  attrs: {
    trigger?: string;
    title?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};

export default function BlockModal({ attrs, innerBlocks }: IBlockModal) {
  const { trigger, title, className } = attrs || {};
  const state = useGlobalContext();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    removeScroll(showModal, state);
  }, [showModal, state]);

  useEffect(() => {
    if (!trigger) return;

    const linkElements = document.querySelectorAll(`#${trigger}`);
    if (trigger && linkElements) {
      [].slice.call(linkElements).forEach((linkElement: HTMLAnchorElement) => {
        linkElement.addEventListener("click", handleOpenClick, {passive: true});
      });
    }

    return () => {
      if (trigger && linkElements)
        [].slice
          .call(linkElements)
          .forEach((linkElement: HTMLAnchorElement) => {
            linkElement.removeEventListener("click", handleOpenClick);
          });
    };
  }, [trigger]);

  const handleOpenClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setShowModal(true);
  };

  const handleCloseClick = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Modal
        title={title}
        open={showModal}
        onClose={() => handleCloseClick()}
        className={className || ""}
        data-lenis-prevent={true}
      >
        {!!innerBlocks?.length &&
          innerBlocks.map((block: object, index: number) => {
            return displayBlock(block as BlocksProps, index);
          })}
      </Modal>
    </div>
  );
}

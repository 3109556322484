"use client";

import { Flex, LogosCard } from "oddsgate-ds";
import { IArchiveGrid, IPost } from "@/lib/wordpress/_types/archiveGrid";

import Image from "next/image";
import React from "react";

export default function BlockLogosGrid({ attrs }: IArchiveGrid) {
  const { grid, className } = attrs || {};

  return (
    <>
      {grid?.items && (
        <Flex justify="center" gap={"1.6rem 0"} className={className || ""}>
          {grid?.items.map((post: IPost, i: number) => {
            return (
              <LogosCard
                key={`logo-${i}`}
                title={post?.title}
                imageElement={
                  post?.image !== "" ? (
                    <Image
                      src={post?.image as string}
                      alt={post?.title as string}
                      width={195}
                      height={40}
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            );
          })}
        </Flex>
      )}
    </>
  );
}

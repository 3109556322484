import classNames from "classnames";

export type IBlockAnimatedSvg = {
  attrs: {
    mediaUrl?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
};

export default function BlockAnimatedSvg({
  attrs,
}: IBlockAnimatedSvg) {
  const { mediaUrl, className, style } =
    attrs || {};

  return (
    <div className={classNames("animated-svg", className)}>
      <object type="image/svg+xml" data={mediaUrl} style={{ width: "100%", pointerEvents: "none" }}>svg-animation</object>
    </div>
  );
}

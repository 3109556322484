import { ErrorMessage, Field } from "formik";

import CustomErrorMessage from "./CustomErrorMessage";
import { FormField } from "oddsgate-ds";
import { IInputs } from "../fieldProps";
import cn from "classnames";
import getGfFieldId from "@/lib/wordpress/forms/getGfFieldId";
import getGfHiddenClassName from "@/lib/wordpress/forms/getHiddenClassName";

/**
 * Render GravityForms Textarea field component.
 *
 * @param  {object}        props             GravityForm Select field as props.
 * @param  {string}        props.cssClass   GravityForm field wrapper class.
 * @param  {string}        props.description GravityForm field description.
 * @param  {string|number} props.id          GravityForm field id.
 * @param  {boolean}       props.isRequired  GravityForm field is required.
 * @param  {string}        props.label       GravityForm field label.
 * @param  {boolean}       props.visibility  GravityForm visibility option.
 * @return {Element}                         The File component.
 */
export default function TextArea({
  cssClass,
  description,
  id,
  isRequired,
  placeholder,
  label,
  visibility,
}: IInputs) {
  const fieldId = getGfFieldId(id);
  const isHiddenClass = getGfHiddenClassName(visibility);
  const thisClassName = cn(cssClass, isHiddenClass);

  return (
    <FormField label={label} id={fieldId}>
      <Field
        as="textarea"
        id={fieldId}
        name={fieldId}
        placeholder={placeholder}
        required={isRequired}
        className={thisClassName}
      ></Field>
      {description && <p>{description}</p>}
      <ErrorMessage name={fieldId} component={CustomErrorMessage} />
    </FormField>
  );
}

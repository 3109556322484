"use client";

import {
  Column,
  EmptyState,
  Loader,
  NewsCard,
  Row,
  Slider,
} from "oddsgate-ds";
import { IArchiveGrid, IFilters, IPost, Taxonomy } from "@/lib/wordpress/_types/archiveGrid";
import React, {
  Suspense,
  useMemo,
  useState,
} from "react";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import Link from "next/link";
import { formatDate } from "@/lib/helpers/dateFormate";
import { useGlobalContext } from "@/lib/globalContext";

export default function BlockBreakingNews({ attrs }: IArchiveGrid) {
  const {
    grid,
    className,
  } = attrs || {};

  const [loading, setLoading] = useState<boolean | null>(false);

  const [filteredPosts, setFilteredPosts] = useState<
    IPost[] | undefined
  >(grid?.items);

  const state = useGlobalContext();

  const { lang } = state;

  const cachedContent = useMemo(() => {
    if (filteredPosts && filteredPosts.length !== 0) {
      return filteredPosts.map((post, i: number) => {
        if (!post) return false;
        const category = post?.categories as unknown as Taxonomy[] || [];
        const categoryName = category && Object.keys(category).length > 0 ? category[0]?.items[0]?.name : "";

        const imageElement = (
          <DisplayImage
            url={post?.image as string}
            alt={post?.title ? (post?.title as string) : ""}
            fill={true}
          />
        );

        return (
          <Column key={`newsCard-${i}`} sm={12} md={i === 0 ? 12 : 4}>
            <NewsCard
              variant={i === 0 ? "featured" : "default"}
              date={formatDate(post?.date as string, "DATE_SHORT")}
              category={categoryName}
              imageElement={imageElement}
              title={post.title as string}
              description={post.excerpt as string}
              linkElement={
                <Link
                  href={post.slug as string}
                  aria-label={`Read more about ${post.title}`}
                  role="link"
                  className="linker"
                ></Link>
              }
            />
          </Column>
        )
      })
    }
  }, [filteredPosts])

  return (
    <Row className={className}>
      {loading ? (
        <Column className="text-center pt-5 pb-5">
          <Loader label={state?.dict?.common?.loading}></Loader>
        </Column>
      ) : (
        <Suspense
          fallback={<Loader label={state?.dict?.common?.loading}></Loader>}
        >
          {cachedContent ? (
            <>
              <Slider
                arrows={true}
                dots={true}
                slidesToShow={3}
                infinite={false}
                autoplay={false}
                mobileOnly={true}
                mediaQueries={{
                  900: {
                    slidesToShow: 2,
                    autoplay: true
                  },
                  500: {
                    slidesToShow: 1
                  }
                }}
              >
                {cachedContent.map((block) => {
                  return block
                })}
              </Slider>
            </>
          ) : (
            <Column className="pt-7 pb-5">
              <EmptyState
                variant={"light"}
                label={state?.dict?.common?.noResults}
              ></EmptyState>
            </Column>
          )}
        </Suspense>
      )}
    </Row>
  );
}

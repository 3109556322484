"use client";

import { useMemo, useRef, useState } from "react";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import classNames from "classnames";
import { styled } from "styled-components";
import { useGSAP } from "@gsap/react";

export type IDisplayBackground = {
  url?: string;
  alt?: string;
  backgroundSize?: string,
  backgroundPosition?: string,
  backgroundAttachment?: string,
  backgroundRepeat?: string,
  className?: string;
  style?: Record<string, unknown>;
};

const StyledBackground = styled.div`
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
`;


const DisplayBackground = ({
  url,
  alt,
  backgroundSize,
  backgroundPosition,
  backgroundAttachment,
  backgroundRepeat,
  className,
  style,
}: IDisplayBackground) => {
  const backgroundElement = useRef<HTMLDivElement>();
  const [backgroundImage, setBackgroundImage] = useState("");


  useGSAP(() => {
    ScrollTrigger.create({
      trigger: backgroundElement.current,
      start: "top bottom",
      scrub: 1,
      once: true,
      invalidateOnRefresh: true,
      onEnter: () => {
        if (backgroundElement?.current) {
          setBackgroundImage(backgroundElement.current.getAttribute("data-background") || "");
          backgroundElement?.current?.removeAttribute("data-background");
        }
      },
    });
  }, { scope: backgroundElement });


  const imgStyles = useMemo(() => ({
    backgroundImage: backgroundImage && `url("${backgroundImage}")`,
    backgroundSize: backgroundSize ?? "cover",
    backgroundPosition: backgroundPosition,
    backgroundAttachment: backgroundAttachment,
    backgroundRepeat: backgroundRepeat,
  }), [backgroundImage, backgroundPosition, backgroundSize, backgroundAttachment, backgroundRepeat]);

  return (
    <StyledBackground
      ref={backgroundElement as any}
      aria-label={alt ? alt : ""}
      data-background={url}
      className={classNames("background-element", className)}
      style={imgStyles as any}
    >
    </StyledBackground>
  );
};

export default DisplayBackground;

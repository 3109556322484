import styled, { css } from 'styled-components';

import { IPaginationStepper } from './PaginationStepper.interface';
import { variables } from 'oddsgate-ds';

export const StyledPagination = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
`
export const StyledStepper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  button {
    width: 4rem;
    height: 4rem;
    color: ${variables.colors.secondary50};
    background-color: ${variables.colors.third10};
    border: none;
    border-radius: 100%;
    transition: all 0.3s linear;

    &:hover,
    &[data-is-active='true'] {
      color: ${variables.colors.secondary50};
      background-color: ${variables.colors.primary50};
    }

    &.dots {
        border: 0;
        border-radius: 0px;
        cursor: default;
    }

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
`

export const StyledSize = styled.div`
  & select{
    color: ${variables.colors.primary50};
    border: 1px solid ${variables.colors.primary50};
    border-radius: 4px;
    font-weight: 600;
    padding: 0px 30px 0px 8px;
  }
`
"use client";

import { Suspense, useEffect, useState } from 'react';

import { Loader } from 'oddsgate-ds'
import dynamic from 'next/dynamic'
import { useGlobalContext } from '@/lib/globalContext';

const Spline = dynamic(() => import('@splinetool/react-spline')) as any

export default function BlockSplineBg({ }) {
  const state = useGlobalContext();  

  return (
    <div className='position-absolute' style={{ width: "100%", height: "115%" }}>
      <Suspense fallback={<Loader label={state?.dict?.common?.loading}></Loader>}>
        <Spline scene="https://prod.spline.design/EQ2FlrukF76IfCIG/scene.splinecode" />
      </Suspense>
    </div>
  );
}

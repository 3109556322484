import BlockAccordion from "@/components/blocks/miewtheme/BlockAccordion/BlockAccordion";
import BlockAnimatedSvg from "@/components/blocks/miewtheme/BlockAnimatedSvg/BlockAnimatedSvg";
import BlockArchiveGrid from "@/components/blocks/miewtheme/BlockArchiveGrid/BlockArchiveGrid";
import BlockBreakingNews from "@/components/blocks/miewtheme/BlockBreakingNews/BlockBreakingNews";
import BlockButton from "@/components/blocks/core/BlockButton/BlockButton";
import BlockCarousel from "@/components/blocks/miewtheme/BlockCarousel/BlockCarousel";
import BlockColumns from "@/components/blocks/core/BlockColumns/BlockColumns";
import BlockCounter from "@/components/blocks/miewtheme/BlockCounter/BlockCounter";
import BlockCover from "@/components/blocks/core/BlockCover/BlockCover";
import BlockFaqsGrid from "@/components/blocks/miewtheme/BlockFaqsGrid/BlockFaqsGrid";
import BlockGravityForm from "@/components/blocks/miewtheme/BlockGravityForm/BlockGravityForm";
import BlockGroup from "@/components/blocks/core/BlockGroup/BlockGroup";
import BlockHeading from "@/components/blocks/core/BlockHeading/BlockHeading";
import BlockHtml from "@/components/blocks/core/BlockHtml/BlockHtml";
import BlockIconBox from "@/components/blocks/miewtheme/BlockIconBox/BlockIconBox";
import BlockIconTitle from "@/components/blocks/miewtheme/BlockIconTitle/BlockIconTitle";
import BlockImage from "@/components/blocks/core/BlockImage/BlockImage";
import BlockList from "@/components/blocks/core/BlockList/BlockList";
import BlockLogosGrid from "@/components/blocks/miewtheme/BlockLogosGrid/BlockLogosGrid";
import BlockMarquee from "@/components/blocks/miewtheme/BlockMarquee/BlockMarquee";
import BlockModal from "@/components/blocks/miewtheme/BlockModal/BlockModal";
import BlockParagraph from "@/components/blocks/core/BlockParagraph/BlockParagraph";
import BlockQuote from "@/components/blocks/core/BlockQuote/BlockQuote";
import BlockReusable from "@/components/blocks/core/BlockReusable/BlockReusable";
import BlockScrollingImgs from "@/components/blocks/miewtheme/BlockScrollingImgs/BlockScrollingImgs";
import BlockScrollingProducts from "@/components/blocks/miewtheme/BlockScrollingProducts/BlockScrollingProducts";
import BlockSearchPage from "@/components/blocks/miewtheme/BlockSearchPage/BlockSearchPage";
import BlockSeparator from "@/components/blocks/core/BlockSeparator/BlockSeparator";
import BlockSliderLicenses from "@/components/blocks/miewtheme/BlockScrollingLicenses/BlockScrollingLicenses";
import BlockSliderProducts from "@/components/blocks/miewtheme/BlockSliderProducts/BlockSliderProducts";
import BlockSocialDrag from "@/components/blocks/miewtheme/BlockSocialDrag/BlockSocialDrag";
import BlockSocialLinks from "@/components/blocks/miewtheme/BlockSocialLinks/BlockSocialLinks";
import BlockSpacer from "@/components/blocks/core/BlockSpacer/BlockSpacer";
import BlockSplineBg from "@/components/blocks/miewtheme/BlockSplineBg/BlockSplineBg";
import BlockTabs from "@/components/blocks/miewtheme/BlockTabs/BlockTabs";
import BlockTaxonomyGrid from "@/components/blocks/miewtheme/BlockTaxonomyGrid/BlockTaxonomyGrid";
import BlockTeamGrid from "@/components/blocks/miewtheme/BlockTeamGrid/BlockTeamGrid";
import BlockVideo from "@/components/blocks/core/BlockVideo/BlockVideo";
import BlockVideoEmbed from "@/components/blocks/core/BlockVideoEmbed/BlockVideoEmbed";
import { BlocksProps } from "../_types/blocksStyle";
import { IGravityForms } from "../forms/types";

// import dynamic from 'next/dynamic'
// const BlockAccordion = dynamic(() => import("@/components/blocks/miewtheme/BlockAccordion/BlockAccordion"), { ssr: false });
// const BlockAnimatedSvg = dynamic(() => import("@/components/blocks/miewtheme/BlockAnimatedSvg/BlockAnimatedSvg"), { ssr: false });
// const BlockArchiveGrid = dynamic(() => import("@/components/blocks/miewtheme/BlockArchiveGrid/BlockArchiveGrid"), { ssr: false });
// const BlockBreakingNews = dynamic(() => import("@/components/blocks/miewtheme/BlockBreakingNews/BlockBreakingNews"), { ssr: false });
// const BlockButton = dynamic(() => import("@/components/blocks/core/BlockButton/BlockButton"), { ssr: false });
// const BlockCarousel = dynamic(() => import("@/components/blocks/miewtheme/BlockCarousel/BlockCarousel"), { ssr: false });
// const BlockColumns = dynamic(() => import("@/components/blocks/core/BlockColumns/BlockColumns"), { ssr: false });
// const BlockCounter = dynamic(() => import("@/components/blocks/miewtheme/BlockCounter/BlockCounter"), { ssr: false });
// const BlockCover = dynamic(() => import("@/components/blocks/core/BlockCover/BlockCover"), { ssr: false });
// const BlockFaqsGrid = dynamic(() => import("@/components/blocks/miewtheme/BlockFaqsGrid/BlockFaqsGrid"), { ssr: false });
// const BlockGravityForm = dynamic(() => import("@/components/blocks/miewtheme/BlockGravityForm/BlockGravityForm"), { ssr: false });
// const BlockGroup = dynamic(() => import("@/components/blocks/core/BlockGroup/BlockGroup"), { ssr: false });
// const BlockHeading = dynamic(() => import("@/components/blocks/core/BlockHeading/BlockHeading"), { ssr: false });
// const BlockHtml = dynamic(() => import("@/components/blocks/core/BlockHtml/BlockHtml"), { ssr: false });
// const BlockIconBox = dynamic(() => import("@/components/blocks/miewtheme/BlockIconBox/BlockIconBox"), { ssr: false });
// const BlockIconTitle = dynamic(() => import("@/components/blocks/miewtheme/BlockIconTitle/BlockIconTitle"), { ssr: false });
// const BlockImage = dynamic(() => import("@/components/blocks/core/BlockImage/BlockImage"), { ssr: false });
// const BlockList = dynamic(() => import("@/components/blocks/core/BlockList/BlockList"), { ssr: false });
// const BlockLogosGrid = dynamic(() => import("@/components/blocks/miewtheme/BlockLogosGrid/BlockLogosGrid"), { ssr: false });
// const BlockMarquee = dynamic(() => import("@/components/blocks/miewtheme/BlockMarquee/BlockMarquee"), { ssr: false });
// const BlockModal = dynamic(() => import("@/components/blocks/miewtheme/BlockModal/BlockModal"), { ssr: false });
// const BlockParagraph = dynamic(() => import("@/components/blocks/core/BlockParagraph/BlockParagraph"), { ssr: false });
// const BlockQuote = dynamic(() => import("@/components/blocks/core/BlockQuote/BlockQuote"), { ssr: false });
// const BlockReusable = dynamic(() => import("@/components/blocks/core/BlockReusable/BlockReusable"), { ssr: false });
// const BlockScrollingImgs = dynamic(() => import("@/components/blocks/miewtheme/BlockScrollingImgs/BlockScrollingImgs"), { ssr: false });
// const BlockScrollingProducts = dynamic(() => import("@/components/blocks/miewtheme/BlockScrollingProducts/BlockScrollingProducts"), { ssr: false });
// const BlockSearchPage = dynamic(() => import("@/components/blocks/miewtheme/BlockSearchPage/BlockSearchPage"), { ssr: false });
// const BlockSeparator = dynamic(() => import("@/components/blocks/core/BlockSeparator/BlockSeparator"), { ssr: false });
// const BlockSliderLicenses = dynamic(() => import("@/components/blocks/miewtheme/BlockScrollingLicenses/BlockScrollingLicenses"), { ssr: false });
// const BlockSliderProducts = dynamic(() => import("@/components/blocks/miewtheme/BlockSliderProducts/BlockSliderProducts"), { ssr: false });
// const BlockSocialDrag = dynamic(() => import("@/components/blocks/miewtheme/BlockSocialDrag/BlockSocialDrag"), { ssr: false });
// const BlockSocialLinks = dynamic(() => import("@/components/blocks/miewtheme/BlockSocialLinks/BlockSocialLinks"), { ssr: false });
// const BlockSpacer = dynamic(() => import("@/components/blocks/core/BlockSpacer/BlockSpacer"), { ssr: false });
// const BlockSplineBg = dynamic(() => import("@/components/blocks/miewtheme/BlockSplineBg/BlockSplineBg"), { ssr: false });
// const BlockTabs = dynamic(() => import("@/components/blocks/miewtheme/BlockTabs/BlockTabs"), { ssr: false });
// const BlockTaxonomyGrid = dynamic(() => import("@/components/blocks/miewtheme/BlockTaxonomyGrid/BlockTaxonomyGrid"), { ssr: false });
// const BlockTeamGrid = dynamic(() => import("@/components/blocks/miewtheme/BlockTeamGrid/BlockTeamGrid"), { ssr: false });
// const BlockVideo = dynamic(() => import("@/components/blocks/core/BlockVideo/BlockVideo"), { ssr: false });
// const BlockVideoEmbed = dynamic(() => import("@/components/blocks/core/BlockVideoEmbed/BlockVideoEmbed"), { ssr: false });


export default function displayBlock(block: BlocksProps, index: number) {
  if (!block) return <></>
  const { attrs, blockName, innerBlocks } = block;

  // prettier-ignore
  switch (blockName) {
    case 'miewtheme/search-page': {
      return <BlockSearchPage attrs={attrs} key={index} />
    }
    case 'core/block': {
      return <BlockReusable innerBlocks={innerBlocks} key={index} />
    }

    case 'core/image': {
      attrs.fill = false
      attrs.className = (attrs?.className || '') + (attrs.mobileHidden ? ' wp-image d-none d-md-inline-block align-top w-100' : ' wp-image d-inline-block align-top w-100');
      attrs.maxWidth = attrs.fixedSize === true ? attrs.width : "";

      return (
        <BlockImage
          key={index}
          attrs={attrs}
        />
      )
    }

    case 'core/html': {
      return <BlockHtml attrs={attrs} key={index} />
    }

    case 'core/heading': {
      return <BlockHeading attrs={attrs} key={index} />
    }

    case 'core/paragraph': {
      return <BlockParagraph attrs={attrs} key={index} />
    }

    case 'miewtheme/button': {
      return <BlockButton attrs={attrs} key={index} />
    }

    case 'core/list': {
      return <BlockList attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/counter': {
      return <BlockCounter attrs={attrs} key={index} />
    }

    case 'core/quote': {
      return <BlockQuote attrs={attrs} key={index} />
    }

    case 'core/video': {
      return <BlockVideo attrs={attrs} key={index} />
    }
    case 'core/embed': {
      attrs.embed = attrs?.url;
      return <BlockVideoEmbed attrs={attrs} key={index} />
    }

    case 'core/columns': {
      return (
        <BlockColumns
          attrs={attrs}
          innerBlocks={innerBlocks}
          key={index}
        />
      )
    }

    case 'core/group': {
      return <BlockGroup attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'core/cover': {
      return <BlockCover attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/accordion': {
      return <BlockAccordion attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/spacer': {
      return <BlockSpacer attrs={attrs} key={index} />
    }

    case 'miewtheme/separator': {
      return <BlockSeparator attrs={attrs} key={index} />
    }

    case 'miewtheme/social-links': {
      return <BlockSocialLinks attrs={attrs} key={index} />
    }

    case 'miewtheme/modal': {
      return <BlockModal attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/iconbox': {
      return <BlockIconBox attrs={attrs} key={index} />
    }

    case 'miewtheme/icontitle': {
      return <BlockIconTitle attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/carousel': {
      return <BlockCarousel attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }
    case 'miewtheme/tabs': {
      return <BlockTabs attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }
    case 'miewtheme/marquee': {
      return <BlockMarquee attrs={attrs} key={index} />
    }
    case 'miewtheme/team-grid': {
      return <BlockTeamGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/archive-grid': {
      return <BlockArchiveGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/taxonomy-grid': {
      return <BlockTaxonomyGrid attrs={attrs} key={index} />
    }
    case 'gravityforms/form': {
      return <BlockGravityForm attrs={attrs as IGravityForms} key={index} />
    }
    case 'miewtheme/faqs-grid': {
      return <BlockFaqsGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/logos-grid': {
      return <BlockLogosGrid attrs={attrs} key={index} />
    }
    case 'miewtheme/social-draggable': {
      return <BlockSocialDrag attrs={attrs} key={index} />
    }
    case 'miewtheme/spline-bg': {
      return <BlockSplineBg key={index} />
    }
    case 'miewtheme/animated-svg': {
      return <BlockAnimatedSvg attrs={attrs} key={index} />
    }
    case 'miewtheme/scrolling-img': {
      return <BlockScrollingImgs attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }
    case 'miewtheme/products-grid': {
      return <BlockScrollingProducts attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }
    case 'miewtheme/products-slider': {
      return <BlockSliderProducts attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }
    case 'miewtheme/licenses-grid': {
      return <BlockSliderLicenses attrs={attrs} innerBlocks={innerBlocks} key={index} />
    }

    case 'miewtheme/breaking-news': {
      return <BlockBreakingNews attrs={attrs} key={index} />
    }

    default: {
      if (process.env.NODE_ENV === 'development') {
        console.log('display block not found', blockName, index)
        return <pre key={index}>{JSON.stringify(block, null, 2)}</pre>
      } else {
        return <></>
      }
    }
  }
}

"use client";

import {
  Button,
  CircularSlider,
  Column,
  EmptyState,
  Loader,
  Row,
  Spacer,
} from "oddsgate-ds";
import React, { Suspense, useState } from "react";

import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import { IArchiveGrid } from "@/lib/wordpress/_types/archiveGrid";
import Link from "next/link";
import { useGlobalContext } from "@/lib/globalContext";

export default function BlockTeamGrid({ attrs }: IArchiveGrid) {
  const { grid, className } = attrs || {};

  const state = useGlobalContext();

  const [filtersChange, setFiltersChange] = useState({
    page: 1,
  });

  const [loading, setLoading] = useState<boolean | null>(false);

  const [filteredPosts, setFilteredPosts] = useState(grid?.items);


  const newData =
    filteredPosts &&
      filteredPosts.length > 0 ?
      filteredPosts.map(block => {
        const result = {
          name: block.title,
          role: block.acf?.role,
          img: (
            <DisplayImage
              url={block?.image as string}
              alt={block?.title ? (block?.title as string) : ""}
              width={330}
              height={330}
            />
          ),
        }

        return result
      }) : ([])

  return (
    <div>
      {filteredPosts ? (
        <Row fluid={true}>
          {loading ? (
            <Column className="text-center pt-5 pb-5">
              <Loader
                label={state?.dict?.common?.loading}
                variant="light"
              ></Loader>
            </Column>
          ) : (
            <>
              <Suspense
                fallback={
                  <Loader
                    label={state?.dict?.common?.loading}
                    variant="light"
                  ></Loader>
                }
              >

                <CircularSlider content={newData} cta={
                  <Link href={`/${state.dict?.custom?.contactLink}`}>
                    <Button>{state.dict?.custom?.joinTeam}</Button>
                  </Link>
                }></CircularSlider>
              </Suspense>
            </>
          )}
        </Row>
      ) : (
        <Column className="pt-5 pb-5">
          <Spacer height={8}></Spacer>
          <EmptyState
            variant={"light"}
            label={state?.dict?.common?.noResults}
          ></EmptyState>
        </Column>
      )}
    </div>
  );
}

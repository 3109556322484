"use client";

import { Accordion, AccordionItem } from "oddsgate-ds";

import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import React from "react";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";

export type IBlockAccordion = {
  attrs: {
    anchor?: string;
    title?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};

export default function BlockAccordion({
  attrs,
  innerBlocks,
}: IBlockAccordion) {
  return (
    <Accordion>
      {!!innerBlocks?.length &&
        innerBlocks.map((item: BlocksProps, index: number) => {
          const attrs = item?.attrs as Record<string, unknown>;
          return (
            <AccordionItem key={`accordion-item${index}`} title={attrs.title as string}>
              {!!item?.innerBlocks?.length &&
                item?.innerBlocks?.map((block: BlocksProps, index: number) => {
                  return displayBlock(block, index);
                })}
            </AccordionItem>
          );
        })}
    </Accordion>
  );
}

import { ErrorMessage, Field } from "formik";

import CustomErrorMessage from "./CustomErrorMessage";
import { FormField } from "oddsgate-ds";
import { IInputs } from "../fieldProps";
import cn from "classnames";
import getGfFieldId from "@/lib/wordpress/forms/getGfFieldId";
import getGfHiddenClassName from "@/lib/wordpress/forms/getHiddenClassName";

export default function Text({
  cssClass,
  description,
  enablePasswordInput,
  errorMessage,
  id,
  isRequired,
  label,
  placeholder,
  type,
  visibility,
  setFieldValue,
  submitElement,
  ...fieldProps
}: IInputs) {
  const fieldId = getGfFieldId(id);
  const isHiddenClass = getGfHiddenClassName(visibility);
  const thisClassName = cn(cssClass, isHiddenClass);

  /**
   * Convert type to an HTML input type.
   *
   * @param  {string} type GravityForm field type.
   * @return {string}      HTML input type.
   */
  function modifyFieldType(type?: string) {
    let inputType = type;

    if (enablePasswordInput && "password") {
      inputType = "password";
    }

    if (type === "phone") {
      inputType = "tel";
    }

    if (type === "website") {
      inputType = "url";
    }

    return inputType;
  }

  const inputType = modifyFieldType(type);

  return (
    <FormField label={isRequired ? `${label}*` : label} id={fieldId}>
      <div className="position-relative">
        <Field
          id={fieldId}
          name={fieldId}
          type={inputType}
          className={thisClassName}
          placeholder={placeholder}
          required={isRequired}
        ></Field>
        {submitElement}
      </div>
      {description && <p>{description}</p>}
      <ErrorMessage name={fieldId} component={CustomErrorMessage} />
    </FormField>
  );
}

"use client";

import { Marquee } from "oddsgate-ds";

export type IBlockMarquee = {
  attrs: {
    anchor?: string;
    text?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
};

export default function BlockMarquee({ attrs }: IBlockMarquee) {
  const { text, className, style } = attrs || {};

  return <Marquee text={text} className={className} style={style}></Marquee>;
}

export type WpRequestVariables = {
  ids?: number[];
  slug?: string;
  lang?: string;
  limit?: number;
  category?: number | string;
  offset?: number;
  order?: string;
  orderby?: string;
};

export const WpClientRequest = async ({
  post_type = "",
  variables = {},
  isContent = true,
  isForms = false,
  forceUpdate = false,
  liveOnly = false,
}: {
  post_type: string;
  variables?: WpRequestVariables;
  isContent?: boolean;
  isForms?: boolean;
  forceUpdate?: boolean;
  liveOnly?: boolean;
}) => {
  const requestObject = JSON.stringify({
    post_type,
    variables,
    isContent,
    isForms,
    // ignore this terms
    // forceUpdate,
    // liveOnly,
  });

  const res = await fetch(
    `/api/findContent?${new URLSearchParams({
      filter: requestObject,
    }).toString()}`);
  return await res.json();
};

"use client";

import { CSSProperties } from "react";
import { VideoEmbed } from "oddsgate-ds";
import classNames from "classnames";
import { useGlobalContext } from "@/lib/globalContext";

export type IBlockVideoEmbed = {
  attrs: {
    anchor?: string;
    embed?: string;
    poster?: string;
    autoplay?: boolean;
    controls?: boolean;
    loop?: boolean;
    muted?: boolean;
    playsInline?: boolean;
    className?: string;
    style?: CSSProperties;
    animation?: string;
  };
};

export default function BlockVideoEmbed({ attrs }: IBlockVideoEmbed) {
  const state = useGlobalContext();

  return (
    <div data-cursor={"play"}>
      <VideoEmbed
        {...attrs}
        poster={attrs?.poster ? attrs?.poster : "/assets/placeholder.png"}
        className={classNames(
          attrs.className,
          attrs.animation && `elements_animated ${attrs.animation}`
        )}
      ></VideoEmbed>
    </div>
  );
}

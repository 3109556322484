import * as GfFields from ".";

import { Column, Row } from "oddsgate-ds";
import { IField, IFields } from "@/lib/wordpress/forms/types";

export default function Fields({ fields, setFieldValue, values, submitElement }: IFields) {
  return (
    <Row fluid={true}>
      {fields.length > 0 &&
        fields.map((field: IField) => {
          const id = field?.id;
          const type = field?.type;
          const formExtraProps = { setFieldValue, value: id && values[id] };
          const submitExtraProps = { submitElement };
          
          let fieldToRender = null;
          switch (type) {
            case "section": // read only block
              fieldToRender = <GfFields.Section {...field} />;
              break;
            case "consent":
              fieldToRender = (
                <GfFields.Consent  key={`field-${id}`} {...field} {...formExtraProps} />
              );
              break;
            case "checkbox":
              fieldToRender = (
                <GfFields.Checkbox key={`field-${id}`} {...field} {...formExtraProps} />
              );
              break;
            case "radio":
              fieldToRender = <GfFields.Radio key={`field-${id}`} {...field} {...formExtraProps} />;
              break;
            case "email":
              fieldToRender = <GfFields.Text key={`field-${id}`} {...field} {...submitExtraProps} />;
              break;
            case "phone":
              fieldToRender = <GfFields.Text key={`field-${id}`} {...field} />;
              break;
            case "select":
              fieldToRender = <GfFields.Select key={`field-${id}`} {...field} />;
              break;
            case "text":
              fieldToRender = <GfFields.Text key={`field-${id}`} {...field} {...submitExtraProps} />;
              break;
            case "number":
              fieldToRender = (
                <GfFields.Text key={`field-${id}`} {...field} type={"number"} />
              );
              break;
            case "date":
              fieldToRender = (
                <GfFields.Text key={`field-${id}`} {...field} type={"date"} />
              );
              break;
            case "website":
              fieldToRender = <GfFields.Text key={`field-${id}`} {...field} />;
              break;

            case "hidden":
              fieldToRender = <GfFields.Hidden key={`field-${id}`} {...field} />;
              break;
            case "textarea":
              fieldToRender = <GfFields.Textarea key={`field-${id}`} {...field} />;
              break;
            case "fileupload":
              fieldToRender = <GfFields.File key={`field-${id}`} {...field} {...formExtraProps} />;
              break;
            default:
              if (process.env.NODE_ENV === "development") {
                fieldToRender = (
                  <pre
                  key={`field-${id}`} 
                  >{`"${type}" GravityForm field is unsupported. ${JSON.stringify(
                    field,
                    null,
                    2
                  )}`}</pre>
                );
              } else {
                fieldToRender = <></>;
              }
              break;
          }

          return (
            <Column key={`holder-${id}`} md={field?.layoutGridColumnSpan || 12}>
              {fieldToRender}
            </Column>
          );
        })}
    </Row>
  );
}

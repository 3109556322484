"use client";

import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import React from "react";
import { Slider } from "oddsgate-ds";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";
import { styled } from "styled-components";

export type IBlockCarousel = {
  attrs: {
    anchor?: string;
    arrows?: boolean;
    dots?: boolean;
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};

const StyledGrid = styled.div`
  display: block;

  .blaze-controls {
    position: absolute !important;
    top: 100%;
    width: 100%;
  }

  & picture {
    max-width: 100% !important;
  }
`;

export default function BlockCarousel({ attrs, innerBlocks }: IBlockCarousel) {
  const { arrows = true, dots = true, className } = attrs || {};
  return (
    <StyledGrid>
      <Slider
        arrows={arrows}
        dots={dots}
        slidesToShow={1}
        infinite={true}
        autoplay={true}
        className={className || ""}
      >
        {!!innerBlocks?.length &&
          innerBlocks.map((block: object, index: number) => {
            return displayBlock(block as BlocksProps, index);
          })}
      </Slider>
    </StyledGrid>
  );
}

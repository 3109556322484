"use client";

import {
  Accordion,
  AccordionItem,
  Column,
  EmptyState,
  Loader,
  RichText,
  Row,
} from "oddsgate-ds";
import { IArchiveGrid, IPost } from "@/lib/wordpress/_types/archiveGrid";
import React, { useState } from "react";

import { useGlobalContext } from "@/lib/globalContext";

export default function BlockFaqsGrid({ attrs }: IArchiveGrid) {
  const { anchor, grid } = attrs || {};

  const [loading, setLoading] = useState<boolean | null>(false);
  const [filteredPosts, setFilteredPosts] = useState<
    IPost[] | undefined
  >(grid?.items);
  const state = useGlobalContext();

  return (
    <Row fluid={true}>
      {loading ? (
        <Column className="text-center pt-5 pb-5">
          <Loader label={state?.dict?.common?.loading}></Loader>
        </Column>
      ) : (
        <Column>
          {filteredPosts && filteredPosts.length !== 0 ? (
            <Accordion className="mt-5">
              {filteredPosts.map((post: IPost, i: number) => {
                if (!post) return false;
                return (
                  <AccordionItem key={`faq-${i}`} title={post.title}>
                    <RichText tag={"p"} className="mt-3">
                      {post.excerpt}
                    </RichText>
                  </AccordionItem>
                );
              })}
            </Accordion>
          ) : (
            <Column className="pt-5 pb-5">
              <EmptyState label={state?.dict?.common?.noResults}></EmptyState>
            </Column>
          )}
        </Column>
      )}
    </Row>
  );
}
